import React from "react";

import whatsappImage from "../../assets/images/whatsapp-48.png";
import instagramImage from "../../assets/images/instagram-48.png";
import facebookImage from "../../assets/images/facebook-48.png";
import "./index.scss";
/* eslint-disable */
function SocialWidget() {
  return (
    <div id="social_widget">
      <div>
        <span>
          <a href="https://wa.me/919501566628" target="_blank">
            {" "}
            <img src={whatsappImage} alt="whatsapp" />
          </a>
        </span>
        &nbsp; &nbsp;
        <span>
          <a
            href="https://www.facebook.com/profile.php?id=61553855828495"
            target="_blank"
          >
            <img src={facebookImage} alt="facebook" />
          </a>
        </span>
        &nbsp; &nbsp;
        <span>
          <a
            href="https://instagram.com/p/C4NXsdWpOs0/"
            target="_blank"
          >
            <img src={instagramImage} alt="instagram" />
          </a>
        </span>
      </div>
    </div>
  );
}

export default SocialWidget;

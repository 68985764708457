const Ratings = [
  {
    stars: 5,
    name: "Kirandeep Kaur",
    comment:
      "I recently used Crosslink Immigiration for my student visa application process, and I couldn't be happier with the experience. From the very beginning, the team was incredibly professional and knowledgeable.",
  },
  {
    stars: 5,
    name: "Minakshi Arora",
    comment:
      "My advisor was extremely well-versed in immigration law and procedures. They clearly explained all the steps involved and addressed any questions I had thoroughly.",
  },
  {
    stars: 4,
    name: "Harman",
    comment:
      "Crosslink Immigiration kept me informed throughout the entire process. They were always prompt in responding to my emails and calls, and they made sure I understood every update.",
  },
  {
    stars: 5,
    name: "Rajbir Kaur",
    comment:
      "The team was very efficient in handling my application. They helped me gather all the necessary documents and ensured everything was submitted correctly and on time.",
  },
  {
    stars: 5,
    name: "Amanbeer",
    comment:
      "The team at Crosslink Immigiration was definitely knowledgeable about immigration law. They were able to answer all my questions about the visa process. Sometimes it took a while to get updates on my application. Overall, I got my visa, happy with process.",
  },
  {
    stars: 5,
    name: "Gurman Kaur",
    comment:
      "My immigration situation was quite complex, and I was feeling overwhelmed. Crosslink Immigiration took the time to understand my unique case and patiently guided me through every step. Thanks to their expertise, my visa application was approved!",
  }
];

export default Ratings;

import React from "react";
import RatingCard from "../../library/card";
import Ratings from "../../constant/ratings";
import "./index.scss";

function Rating() {
  return (
    <div id="ratings">
      <h3>Our Reviews</h3>
      <div>
        {" "}
        {Ratings.map((ratingObj) => (
          <RatingCard
            name={ratingObj.name}
            stars={ratingObj.stars}
            commnet={ratingObj.comment}
          />
        ))}
      </div>
    </div>
  );
}

export default Rating;

import React from "react";
import Home from "./containers/home";
import Header from "./components/header";
import Footer from "./components/footer";
import SocialWidget from "./components/socialWidget";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header />
      {/* <Advertise /> */}
      <div id="app_body">
        {" "}
        <Home />
      </div>
      <Footer />
      <SocialWidget />
    </div>
  );
}

export default App;

import React from "react";
import './index.scss';

function Footer() {
  return (
    <div id="footer">
      <div>
        <p><strong>Email</strong>: <em>crosslinkvisa@gmail.com</em></p>
        <p><strong>Phone Number</strong>: 95015-66628 | 9501766628</p>
      </div>
      <div>
        <h2>CrossLink Immigiration</h2>
        <p id='_address'>
          Above HDFC bank, Near Bus Stand
          <br />
          Faridkot, Punjab 151203
          <br />
          India
        </p>
      </div>
    </div>
  );
}

export default Footer;

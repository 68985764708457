const usefulLinks = {
  ILETS: {
    1: { title: "The Official IELTS Website", url: "https://ielts.org/" },
    2: {
      title: "British Council Website for IELTS",
      url: "http://takeielts.britishcouncil.org/?_ga=1.160190269.1468233120.1417537481",
    },
    3: {
      title: "IDP Website for IELTS",
      url: "http://www.idp.com/global/ielts",
    },
    4: {
      title: "IELTS Frequently Asked Questions",
      url: "https://ieltsliz.com/ielts-help-faq/",
    },
  },
  PTE: {
    1: { title: "PTE website", url: "https://www.pearsonpte.com/" },
    2: {
      title: "PTE help center",
      url: "https://www.pearsonpte.com/help-center",
    },
    3: {
      title: "PTE Help Center: Preparation",
      url: "https://www.pearsonpte.com/help-center/preparation",
    },
  },
  "TOP COLLEGES": {
    1: {
      title: "CANADA",
      url: "https://www.collegeadvisor.com/resources/best-colleges-in-canada/",
    },
    2: {
      title: "UK",
      url: "https://www.pearsonpte.com/articles/the-best-universities-in-the-uk-for-international-students",
    },
    3: {
      title: "AUSTRALIA",
      url: "https://www.idp.com/philippines/study-in-australia/top-universities-in-australia/",
    },
  },
};

export default usefulLinks;

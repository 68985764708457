import React, { useState } from "react";
import emailImage from "../../../assets/images/email-48.png";
import whatsappImage from "../../../assets/images/whatsapp-48.png";
import instagramImage from "../../../assets/images/instagram-48.png";
import facebookImage from "../../../assets/images/facebook-48.png";
import "./index.scss";

function ContactUs() {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [question, setQuestion] = useState("");
  const isSubmitEnabled = name && subject && question;
  return (
    <div id="contact_us">
      <div>
        <p>Have a Questions? Contact us!</p>
        <form>
          <label for="fname">Full Name</label>
          <br />
          <input
            type="text"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <label for="lname">Subject</label>
          <br />
          <input
            type="text"
            id="subject"
            name="subject"
            onChange={(e) => setSubject(e.target.value)}
          />
          <br />
          <label for="lname">Question?</label>
          <br />
          <textarea
            rows="10"
            cols="45"
            id="question"
            name="question"
            onChange={(e) => setQuestion(e.target.value)}
          />
          <br />
          <a
            className={`${!isSubmitEnabled ? "_disabled" : ""}`}
            href={`mailto:crosslinkvisa@gmail.com?&subject=${subject}&body=${question}`}
          >
            Submit
          </a>
        </form>
      </div>

      {/* <div>
        <p>You can also contact us by clicking any of the following link.</p>
        <p>
          <span>
            <a href="https://wa.me/919501566628">
              {" "}
              <img src={whatsappImage} alt="whatsapp" />
            </a>
          </span>
          &nbsp; &nbsp;
          <span>
            <img href="https://www.facebook.com/profile.php?id=61553855828495" src={facebookImage} alt="facebook" />
          </span>
          &nbsp; &nbsp;
          <span>
            <a href="https://www.instagram.com/crosslink_immigration_faridkot?igsh=MTJteDU2MzVwbTZ0aA=="><img src={instagramImage} alt="instagram" /></a>
          </span>
          <hr />
        </p>
      </div> */}
    </div>
  );
}

export default ContactUs;

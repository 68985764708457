import React from "react";
import {
  // PopularLinks,
  ContactUs,
  UsefulLinks,
  Intro,
  Rating,
} from "../../components/home";

/* eslint-disable */
function Home({}) {
  return (
    <div id="home_container">
      <Intro />
      <ContactUs />
      <Rating />
      <UsefulLinks />
    </div>
  );
}

export default Home;

import React from "react";
import app_logo from "../../../assets/images/app_logo.png";
import "./index.scss";

function Intro() {
  const isMobile = window.innerWidth < 600;

  return (
    <div id="intro">
      <div id="logo">
        <img src={app_logo} alt="logo" />
      </div>
      <div id="mission" className={`${isMobile ? "_mobile" : "_desktop"}`}>
        <p>
          We guide students to perfect-fit colleges abroad, boosting success
          rates and expanding access to global education. Our goal is to be the
          trusted resource for a seamless international education journey.
        </p>
      </div>
    </div>
  );
}

export default Intro;

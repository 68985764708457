import React, { useEffect, useState } from "react";
import { navLinks } from "../constant/navigation";
import app_logo from "../../assets/images/app_logo.png";
import scrollIntoView from "scroll-into-view";
import "./index.scss";

const idList = {
  [navLinks.home]: "intro",
  [navLinks.contactUs]: "contact_us",
  [navLinks.usefulLinks]: "useful_links",
  // [navLinks.askQuestions]: "popular_links",
};

function Header() {
  const [activeItem, setActiveItem] = useState(navLinks.home);
  let viewActiveItem = "";

  const handleClick = (type) => {
    const ele = document.getElementById(idList[type]);
    setActiveItem(type)
    scrollIntoView(ele);
  };

  useEffect(() => {
    window.removeEventListener("scroll", handleScroll, true);
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = (e) => {
    if (e) {
      let topOffset = 1000000;
      const tabPos = {};
      Object.keys(idList).forEach((key) => {
        const viewportOffset = document
          ?.getElementById(idList[key])
          ?.getBoundingClientRect();
        tabPos[key] = Math.abs(viewportOffset?.top ?? null) || null;
      });
      Object.keys(tabPos).forEach((key) => {
        if (topOffset >= tabPos[key]) {
          topOffset = tabPos[key];
          viewActiveItem = key;
        }
      });
      setActiveItem(viewActiveItem);
    }
  };
  return (
    <div id="header">
      <div id="logo">
        <img src={app_logo} alt="logo" />
      </div>
      <div id="header_items">
        <span
          onClick={() => handleClick(navLinks.home)}
          class={`${activeItem === navLinks.home ? "_active" : ""}`}
        >
          Home
        </span>
        <span
          onClick={() => handleClick(navLinks.contactUs)}
          class={`${activeItem === navLinks.contactUs ? "_active" : ""}`}
        >
          Contact us
        </span>
        {/* <span
          onClick={() => handleClick(navLinks.askQuestions)}
          class={`${activeItem === navLinks.askQuestions ? "_active" : ""}`}
        >
          Ask Questions
        </span> */}
        <span
          onClick={() => handleClick(navLinks.usefulLinks)}
          class={`${activeItem === navLinks.usefulLinks ? "_active" : ""}`}
        >
          Help
        </span>
      </div>
    </div>
  );
}

export default Header;

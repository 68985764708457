import React from "react";
import { Rating } from "react-simple-star-rating";
import "./index.scss";

function RatingCard({stars = 5, name = "", commnet = ""}) {
  return (
    <div className="rating_card">
      <div>
        {" "}
        <Rating
          initialValue={stars}
          onClick={() => null}
          readonly
        />
      </div>
      <p>{name}</p>
      <section><em>{commnet}</em></section>
    </div>
  );
}

export default RatingCard;

import React from "react";
import usefulLinks from "../../constant/usefulLink";
import "./index.scss";

function UsefulLinks() {
  return (
    <div id="useful_links">
      <div>
        {Object.keys(usefulLinks).map((type) => (
          <div>
            <h5>{type}</h5>
            <ul>
              {Object.keys(usefulLinks[type])?.map((item) => (
                <li>
                  <a href={usefulLinks[type][item][`url`]}>
                    {usefulLinks[type][item][`title`]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UsefulLinks;
// usefulLinks[type][item][`url`]
